import { EnumMenu } from 'model';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { MenuModel } from '../../model/app';

export const ModulosMock: MenuModel[] = [

  new MenuModel('Revendedores', EnumMenu.REVENDEDORES_GROUP, [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FuncionarioSoftwareHouse, EnumTipoPessoas.Revendedor, EnumTipoPessoas.GerenteComercial, EnumTipoPessoas.Funcionario, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto, EnumTipoPessoas.Representante], '', 2),
  new MenuModel('Software House', EnumMenu.SOFTWAREHOUSE_GROUP, [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FuncionarioSoftwareHouse, EnumTipoPessoas.GerenteComercial, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto], '', 3),
  new MenuModel('Configurações', EnumMenu.CONFIGURACOES_GROUP, [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.Revendedor, EnumTipoPessoas.GerenteComercial, EnumTipoPessoas.Funcionario, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto, EnumTipoPessoas.Representante], '', 15),

  new MenuModel("Dashboard", EnumMenu.DASHBOARD, [EnumTipoPessoas.Revendedor, EnumTipoPessoas.FuncionarioSoftwareHouse, EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.GerenteComercial, EnumTipoPessoas.Funcionario, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto, EnumTipoPessoas.Representante], '/dashboard', 1),

  new MenuModel('Clientes', EnumMenu.REVENDEDORES_CLIENTES, [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FuncionarioSoftwareHouse, EnumTipoPessoas.Revendedor, EnumTipoPessoas.GerenteComercial, EnumTipoPessoas.Funcionario, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto, EnumTipoPessoas.Representante], '/clientes', 4, EnumMenu.REVENDEDORES_GROUP),
  new MenuModel('Funcionários', EnumMenu.REVENDEDORES_FUNCIONARIOS, [EnumTipoPessoas.Revendedor], '/funcionarios', 18, EnumMenu.REVENDEDORES_GROUP),
  new MenuModel('Planos', EnumMenu.REVENDEDORES_PLANOS, [EnumTipoPessoas.Revendedor, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto], '/planos', 5, EnumMenu.REVENDEDORES_GROUP),
  new MenuModel('Terminais', EnumMenu.REVENDEDORES_TERMINAIS, [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FuncionarioSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto, EnumTipoPessoas.Revendedor, EnumTipoPessoas.Funcionario, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto], '/terminais', 6, EnumMenu.REVENDEDORES_GROUP),
  new MenuModel('Faturas de Clientes', EnumMenu.REVENDEDORES_CLIENTEFATURAS, [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.Revendedor, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto, EnumTipoPessoas.Representante], '/faturas', 7, EnumMenu.REVENDEDORES_GROUP),
  new MenuModel('Licenças de Clientes', EnumMenu.REVENDEDORES_LICENÇAS, [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto], '/licenças', 8, EnumMenu.REVENDEDORES_GROUP),
  new MenuModel('Faturas de Revendas', EnumMenu.REVENDEDORES_NOTASFISCAISREVENDA, [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto, EnumTipoPessoas.GerenteComercial], '/revenda/notas-fiscais', 22, EnumMenu.REVENDEDORES_GROUP),
  new MenuModel('Minhas Faturas', EnumMenu.REVENDEDORES_NOTASFISCAISREVENDA, [EnumTipoPessoas.Revendedor, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto], '/revenda/notas-fiscais', 8, EnumMenu.REVENDEDORES_GROUP),
  new MenuModel('Tabela de Preço', EnumMenu.SOFTWAREHOUSE_TABELAPRECO, [EnumTipoPessoas.Revendedor], '/tabela-precos', 12, EnumMenu.REVENDEDORES_GROUP),
  new MenuModel('Indicações', EnumMenu.REVENDEDORES_INDICACOES, [EnumTipoPessoas.GerenteComercial, EnumTipoPessoas.Revendedor, EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto], '/indicacoes/disponiveis', 28, EnumMenu.REVENDEDORES_GROUP),
  new MenuModel('Comissões', EnumMenu.REVENDEDORES_COMISSOES, [EnumTipoPessoas.Revendedor, EnumTipoPessoas.GerenteComercial, EnumTipoPessoas.FuncionarioFinanceiro], '/comissoes/disponiveis', 20, EnumMenu.REVENDEDORES_GROUP),
  new MenuModel('Simulação de Contrato', EnumMenu.REVENDEDORES_SIMULACAO, [EnumTipoPessoas.Revendedor, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto, EnumTipoPessoas.Representante], '/simulacao-contrato', 21, EnumMenu.REVENDEDORES_GROUP),
  new MenuModel('Representantes', EnumMenu.REVENDEDORES_REPRESENTANTES, [EnumTipoPessoas.Revendedor], '/representantes', 25, EnumMenu.REVENDEDORES_GROUP),
  new MenuModel('Desempenho Revendedores', EnumMenu.REVENDEDORES_DESEMPENHO, [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.GerenteComercial, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto], '/desempenho-revendedores', 28, EnumMenu.REVENDEDORES_GROUP),
  new MenuModel('Meu Desempenho', EnumMenu.REVENDEDORES_DESEMPENHO, [EnumTipoPessoas.Revendedor], '/desempenho-revendedores', 29, EnumMenu.REVENDEDORES_GROUP),


  new MenuModel('Sistemas', EnumMenu.SOFTWAREHOUSE_SISTEMAS, [EnumTipoPessoas.SoftwareHouse], '/sistemas', 9, EnumMenu.SOFTWAREHOUSE_GROUP),
  new MenuModel('Revendedores', EnumMenu.SOFTWAREHOUSE_REVENDEDORES, [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.GerenteComercial, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto, EnumTipoPessoas.FuncionarioSoftwareHouse], '/revendedor', 10, EnumMenu.SOFTWAREHOUSE_GROUP),
  new MenuModel('Formas de Pagamento', EnumMenu.SOFTWAREHOUSE_FORMAPAGAMENTO, [EnumTipoPessoas.SoftwareHouse], '/formas-pagamento', 11, EnumMenu.SOFTWAREHOUSE_GROUP),
  new MenuModel('Tabela de Preço', EnumMenu.SOFTWAREHOUSE_TABELAPRECO, [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto], '/tabela-precos', 14, EnumMenu.SOFTWAREHOUSE_GROUP),
  new MenuModel('Gerentes Comerciais', EnumMenu.SOFTWAREHOUSE_GERENTES, [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto], '/gerentes', 17, EnumMenu.SOFTWAREHOUSE_GROUP),
  new MenuModel('Funcionários', EnumMenu.REVENDEDORES_FUNCIONARIOS, [EnumTipoPessoas.SoftwareHouse], '/funcionarios', 19, EnumMenu.SOFTWAREHOUSE_GROUP),
  new MenuModel('Representantes', EnumMenu.SOFTWAREHOUSE_REPRESENTANTES, [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto], '/representantes', 24, EnumMenu.SOFTWAREHOUSE_GROUP),
  new MenuModel('Comissões', EnumMenu.SOFTWAREHOUSE_COMISSOES, [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse], '/comissoes/disponiveis', 16, EnumMenu.SOFTWAREHOUSE_GROUP),
  new MenuModel('Agente Externo', EnumMenu.SOFTWAREHOUSE_AGENTE_EXTERNO, [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto], '/agente-externo', 23, EnumMenu.SOFTWAREHOUSE_GROUP),
  new MenuModel('Notas Fiscais Rejeitadas', EnumMenu.SOFTWAREHOUSE_NF_REJEITADAS, [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto], '/notas-fiscais-rejeitadas', 26, EnumMenu.SOFTWAREHOUSE_GROUP),
  new MenuModel('Classificações', EnumMenu.SOFTWAREHOUSE_CLASSIFICACOES, [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto], '/classificacoes', 27, EnumMenu.SOFTWAREHOUSE_GROUP),
  new MenuModel('Cliente Ideal', EnumMenu.SOFTWAREHOUSE_PERGUNTAS, [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto], '/cliente-ideal', 28, EnumMenu.SOFTWAREHOUSE_GROUP),

  new MenuModel('Configurações', EnumMenu.CONFIGURACOES, [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.Revendedor, EnumTipoPessoas.GerenteComercial, EnumTipoPessoas.Funcionario, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto, EnumTipoPessoas.Representante], '/configuracoes', 13, EnumMenu.CONFIGURACOES_GROUP),
];
