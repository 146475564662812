import { useEffect, useState, useCallback, useMemo } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./faturas-list-styles";
import { FaturaListData } from "./faturas-list-data";
import { Paginacao } from "views/components/paginacao";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { FaturasListSearchProps } from "./faturas-list-search-props";
import { useGetFaturas } from "data/api/gestao/faturas";
import { isEmpty } from "lodash";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { EnumTipoFatura } from "model/enums/enum-tipo-fatura";
import { FaturasModel } from "model/api/gestao/fatura/faturas-model";
import { useHistory } from "react-router";
import { useGetFaturasExportarExcel } from "data/api/gestao/faturas/get-faturas-exportar-excel";
import { SituacaoMock } from "data/mocks/situacao-mock";
import { useThemeQueries } from "views/theme";
import { ButtonFabMenu } from "views/components/controles/buttons/button-fab-menu";
import { MenuVerticalIcon } from "views/components/icons";
import { ButtonFabMenuModel } from "model/app/components/button-fab-menu/button-fab-menu";
import { ExportarExcelIcon } from "views/components/icons/exportar-excel-icon";
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles";

export interface FaturasListProps {
  searchProps: FaturasListSearchProps;
}

export const FaturasList = (props: FaturasListProps) => {
  const classes = useStyles();
  const listClasses = useDefaultCadastroStyles();
  const { getFaturas, carregando: carregandoFaturas } = useGetFaturas();
  const { tipoUsuario, usuario } = useSessaoAtual()
  const { getFaturasExportarExcel, carregando: carregandoExcel } = useGetFaturasExportarExcel();
  const { location, push } = useHistory();
  const { showToast } = useToastSaurus();
  const { isMobile } = useThemeQueries();

  const carregando = carregandoExcel || carregandoFaturas

  const isRevendedor = tipoUsuario() === EnumTipoPessoas.Revendedor

  // const getCurrentMonth = useCallback(() => {
  //   const month = new Date().getMonth() + 1
  //   const year = new Date().getFullYear()
  //   return {
  //     inicial: new Date(`${month}/01/${year}`),
  //     final: new Date(`${month}/31/${year}`)
  //   }
  // }, [])

  const [valores, setValores] = useState({
    Encargo: 0,
    MultaJuros: 0,
    Valor: 0,
    ValorCusto: 0,
    ValorPago: 0,
  });

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<FaturasModel>(),
  });

  const [selectedList, setSelectedList] = useState<Array<string>>([]);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    setOpenMenu(true);
  }, [])

  const handleClose = useCallback(() => {
    setOpenMenu(false);
  }, [])

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<FaturasModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    []
  );

  const fillResultValores = useCallback(
    async (
      Encargo: number,
      MultaJuros: number,
      Valor: number,
      ValorCusto: number,
      ValorPago: number,
    ) => {
      setValores({
        Encargo: Encargo,
        MultaJuros: MultaJuros,
        Valor: Valor,
        ValorCusto: ValorCusto,
        ValorPago: ValorPago,
      });
    },
    [],
  );


  // const dataFormatada = useCallback((val) => {
  //   const data = new Date(val);
  //   const dia = data.getDate();
  //   const mes = data.getMonth() + 1;
  //   const ano = data.getFullYear();
  //   return `${ano}-${mes.toString().length === 1 ? '0' + mes : mes}-${dia.toString().length === 1 ? '0' + dia : dia
  //     }`;
  // }, []);


  const search = useCallback(
    async (newPage: number) => {
      try {
        const searchParams = new URLSearchParams();

        const beneficiarioId = (isRevendedor ? usuario?.Id : [EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto].includes(tipoUsuario()) ? usuario?.RevendaId : '')

        searchParams.append('Tipo', EnumTipoFatura.Cliente.toString())
        searchParams.append('FiltroPorData', props.searchProps.tipoData.toString());

        if (!isEmpty(beneficiarioId)) {
          searchParams.append('RevendaId', beneficiarioId!)
        }

        if (!isEmpty(props.searchProps.dataInicial)) {
          searchParams.append('DataInicial', props.searchProps.dataInicial)
        }

        if (!isEmpty(props.searchProps.dataFinal)) {
          searchParams.append('DataFinal', props.searchProps.dataFinal)
        }

        if (!isEmpty(props.searchProps.NomePagador)) {
          searchParams.append('NomePagador', props.searchProps.NomePagador)
        }

        if (!isEmpty(props.searchProps.cpfCnpjPagador)) {
          searchParams.append('cpfCnpjPagador', props.searchProps.cpfCnpjPagador)
        }

        if (props.searchProps.formaPagamentoId !== -1) {
          searchParams.append('FormaPagamentoId', props.searchProps.formaPagamentoId)
        }

        if (!isEmpty(props.searchProps.CompetenciaInicial)) {
          searchParams.append('CompetenciaInicial', props.searchProps.CompetenciaInicial)
        }

        if (!isEmpty(props.searchProps.CompetenciaFinal)) {
          searchParams.append('CompetenciaFinal', props.searchProps.CompetenciaFinal)
        }

        if (props.searchProps.Situacao > -1) {
          searchParams.append('Situacao', props.searchProps.Situacao.toString())
        }

        if (!isEmpty(props.searchProps.nossoNumero)) {
          searchParams.append('NossoNumero', props.searchProps.nossoNumero!.toString())
        }

        const query = '&' + searchParams.toString();

        const res = await getFaturas(newPage, query);
        if (res.erro) throw res.erro;

        if (!res.resultado) return

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }
        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list
        );
        if (res.resultado.data.somatorios) {
          fillResultValores(
            res.resultado.data.somatorios.Encargo,
            res.resultado.data.somatorios.MultaJuros,
            res.resultado.data.somatorios.Valor,
            res.resultado.data.somatorios.ValorCusto,
            res.resultado.data.somatorios.ValorPago
          )
        }

        return res;
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [isRevendedor, usuario?.Id, usuario?.RevendaId, tipoUsuario, props.searchProps, getFaturas, fillResult, fillResultValores, showToast]
  );

  useEffect(() => {
    let page = Number(new URLSearchParams(location.search).get('page'));
    search(page || queryStatus.page);
    location.search = ''
  }, [location, queryStatus.page, search]);

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        await search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  const onCardSelected = (id: string) => {
    push({
      pathname: '/faturas/' + id,
      state: {
        from: {
          pathname: location.pathname,
          page: queryStatus.page
        }
      }
    })
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  const exportExcel = useCallback(async (newPage: number) => {
    const query =
      `&FiltroPorData=${props.searchProps.tipoData}
      ${!isEmpty(props.searchProps.dataInicial)
        ? '&DataInicial=' + props.searchProps.dataInicial
        : ''
      }${!isEmpty(props.searchProps.dataFinal)
        ? '&DataFinal=' + props.searchProps.dataFinal
        : ''
      }${`&Tipo=${EnumTipoFatura.Cliente}`
      }${!isEmpty(props.searchProps.NomePagador)
        ? '&NomePagador=' + props.searchProps.NomePagador
        : ''
      }${!isEmpty(props.searchProps.cpfCnpjPagador)
        ? '&CpfCnpjPagador=' + props.searchProps.cpfCnpjPagador
        : ''
      }${props.searchProps.Situacao > -1
        ? '&Situacao=' + props.searchProps.Situacao
        : ''
      }${props.searchProps.formaPagamentoId !== -1
        ? '&FormaPagamentoId' + props.searchProps.formaPagamentoId
        : ''
      }${!isEmpty(props.searchProps.nossoNumero)
        ? '&NossoNumero=' + props.searchProps.nossoNumero
        : ''
      }${tipoUsuario() === EnumTipoPessoas.Revendedor
        ? '&RevendaId=' + usuario?.Id
        : ''}`

    try {
      const res = await getFaturasExportarExcel(newPage, query)
      if (res.erro) throw (res.erro)
      if (!res.resultado) return;

      const blob = new Blob([res.resultado?.data], { type: 'application/vnd.ms-excel' },);

      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a')

      anchor.href = url
      anchor.download = `Faturas(${SituacaoMock.find(x => x.Key === props.searchProps.Situacao)?.Value || 'Todos'})-${new Date().toLocaleDateString().replaceAll('/', '-')}.xls`
      anchor.click()

    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [props.searchProps, tipoUsuario, usuario?.Id, getFaturasExportarExcel, showToast])

  const onClickMenuItem = useMemo(() => {
    const itens = new Array<ButtonFabMenuModel>();

    itens.push(new ButtonFabMenuModel(<ExportarExcelIcon tipo='BUTTON_FAB' />, 'Exportar Excel', () => exportExcel(1)))

    return itens;
  }, [exportExcel])

  const podeVerCusto = useMemo(() => (
    ![
      EnumTipoPessoas.Representante,
      EnumTipoPessoas.FuncionarioFinanceiroSemCusto,
      EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto,
      EnumTipoPessoas.Funcionario,
      EnumTipoPessoas.FuncionarioSoftwareHouse,
    ].includes(tipoUsuario())
  ), [tipoUsuario])

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Box display='flex' flexDirection='column' height='100%' justifyContent='space-between' flex={1} overflow='auto' >
          <Grid container className={listClasses.listContainer}>
            <Grid item xs={12}>
              <Paginacao
                pageChanged={pageChanged}
                totalPages={queryStatus.totalPages}
                totalRegisters={queryStatus.totalResults}
                currentPage={queryStatus.page}
                exportarExcel={() => exportExcel(1)}
              />
            </Grid>
            <Grid item xs={12} className={classes.listContainer}>
              <FaturaListData
                carregando={carregando}
                list={queryStatus.list}
                selectedList={selectedList}
                onCardSelected={onCardSelected}
                onCardChecked={onCardChecked}
              />
            </Grid>
          </Grid>
          {(queryStatus.totalResults > 0) ?
            <div className={classes.footer}>
              <Box p={1} display={'flex'} flexWrap='wrap' alignItems={'center'} justifyContent={"space-between"} width={"100%"}>
                <Box ml={1}>
                  <Typography variant='caption'>
                    Encargo
                  </Typography>
                  <Typography variant="body1">
                    <Box fontWeight={600}>
                      {valores.Encargo.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </Box>
                  </Typography>
                </Box>
                <Box ml={1}>
                  <Typography variant='caption'>
                    Multa Juros
                  </Typography>
                  <Typography variant="body1">
                    <Box fontWeight={600}>
                      {valores.MultaJuros.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </Box>
                  </Typography>
                </Box>
                <Box ml={1}>
                  <Typography variant='caption'>
                    Valor
                  </Typography>
                  <Typography variant="body1">
                    <Box fontWeight={600}>
                      {valores.Valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </Box>
                  </Typography>
                </Box>
                {(podeVerCusto) && <Box ml={1}>
                  <Typography variant='caption'>
                    Valor Custo
                  </Typography>
                  <Typography variant="body1">
                    <Box fontWeight={600}>
                      {valores.ValorCusto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </Box>
                  </Typography>
                </Box>}
                <Box ml={1}>
                  <Typography variant='caption'>
                    Valor Pago
                  </Typography>
                  <Typography variant="body1">
                    <Box fontWeight={600}>
                      {valores.ValorPago.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </div>
            :
            ''
          }
        </Box >
      </div >

      {isMobile ? (
        <>
          <ButtonFabMenu
            open={openMenu}
            onOpen={handleOpen}
            onClose={handleClose}
            values={onClickMenuItem}
            icon={<MenuVerticalIcon tipo='BUTTON_FAB' />}
            style={{
              zIndex: 0,
              bottom: '60px',
            }}
          />
        </>
      ) : null}

    </>
  );
};
